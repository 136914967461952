import * as React from 'react'
import { graphql } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import AdSlot from '../components/AdSlot'

const components = {
    preformatted: ({ node, text }) => (
        <div dangerouslySetInnerHTML={{ __html: text }} />
    )
}

const Page = ({ data }) => {
    if (!data) return null

    const document = data?.prismicPage?.data
    const settings = data?.prismicGeneralSettings?.data

    return (
        <Layout>
            <Seo
                title={
                    document?.seo_title?.text ||
                    prismicH.asText(document?.page_title?.richText)
                }
                description={document?.seo_description?.text}
                ogImage={document?.og_image?.url}
                robots={document?.meta_robots?.text}
            />
            <div className="bg-gray-100">
                {/* Other Slot */}
                <AdSlot html={settings?.other_slot?.text} />
            </div>
            <div className="grid md:grid-cols-3 gap-16 mx-auto px-6 pt-12 max-w-7xl">
                <div className="md:col-span-2">
                    <div className="mb-4">
                        <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                            {prismicH.asText(document?.page_title?.richText)}
                        </h1>
                    </div>
                    <div className="pb-20 prose prose-lg max-w-none text-gray-600">
                        <PrismicRichText
                            field={document.page_content.richText}
                            components={components}
                        />
                    </div>
                </div>
                <div>
                    {/* Sidebar Slot */}
                    <AdSlot html={document?.page_sidebar_slot?.text} />
                </div>
            </div>
        </Layout>
    )
}

export default Page

export const query = graphql`
    query PageQuery($uid: String!) {
        prismicGeneralSettings {
            data {
                other_slot {
                    text
                }
            }
        }
        prismicPage(uid: { eq: $uid }) {
            data {
                page_title {
                    richText
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                meta_robots {
                    text
                }
                og_image {
                    url
                }
                page_content {
                    richText
                }
                page_sidebar_slot {
                    text
                }
            }
        }
    }
`
